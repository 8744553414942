<template>
  <div class="serve">
    <div class="inbanner clearfix">
      <div class="swiper-box">
        <swiper :options="swiperOption" ref="mySwiper" class="swiper-wrapper"
          @slide-change-transition-end="handleClickSlide">
          <swiper-slide v-for="(item, index) in swiperList.items" :key="index" class="swioer-item"
            swiper-animate-effect="shutter2">
            <div class="swiper-box">
              <img :src="item.imgs ? (JSON.parse(item.imgs)[langtype] ? JSON.parse(item.imgs)[langtype] : item.img) : item.img"
                alt="" />
              <!-- <div :class="'center' + index">
              <p class="name">{{item.name}}</p>
              <p class="title">{{item.title}}</p>
              <p class="description">{{item.description}}</p>
              <div class="btn-box" v-if="index !== 0">
                <span>立即选购 </span>|
                <span>  了解详情</span>
              </div>
            </div> -->
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <!-- <div class="sign-box">
        <img class="sign" src="../assets/home/shubiao.png" alt="" />
      </div> -->
      </div>
    </div>

    <div class="center">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{ $t('common.sy') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('serve.fwzc') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ location }}</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="tabs-min">
        <ul>
          <li v-for="(item, index) in tabList" :class="[tabShou == index ? 'active' : 'tab']" @click="addTab(item, index)"
            :key="index">
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="news-box" style="flex-wrap: wrap">
        <div style="width: 100%; height: auto; padding: 20px 0;padding-top: 0; text-align: right">
          <input type="text" v-model="input" style="padding: 10px; border: none" :placeholder="$t('serve.zwcx')" />
          <button style="height: 100%;padding: 0 20px; border: none;border-radius: 0;background-color: #7f0114;color: #fff;
            " @click="authenticitys">
            {{ $t('serve.zwcx') }}
          </button>
          <!-- <el-input size="small"
                    v-model="input"
                    placeholder="请输入IMEI号"></el-input> -->
          <!-- <el-button class="primary"
                     type="primary"
                     @click="authenticitys">真伪查询</el-button> -->
        </div>
        <div class="tabs">
          <div class="title">{{ $t('serve.fwzc') }}</div>
          <ul>
            <li v-for="(item, index) in tabList" :class="[tabShou == index ? 'active' : '']" @click="addTab(item, index)"
              :key="index">
              {{ item.name }}
            </li>
          </ul>
          <img src="../../assets/dynamic/1.jpg" alt="" />
        </div>
        <!-- <div class="news-cen"
             v-if="tabShou == 1">
          <div class="app-box high">
            <div class="authenticity">
              <el-input class="inputs"
                        size="medium"
                        v-model="input"
                        placeholder="请输入IMEI号"></el-input>
              <el-button class="primary"
                         type="primary"
                         @click="authenticitys">真伪查询</el-button>
            </div>
          </div>
        </div> -->
        <div class="news-cen" style="flex: 1; width: 1%" v-if="tabShou == 0">
          <div class="datum-box" style="margin-top: 0">
            <ul>
              <li v-for="(item, index) in datumList" :key="index" style="padding: 10px; box-sizing: border-box">
                <p :title="item.file_name">{{ item.file_names ? JSON.parse(item.file_names)[langtype] : '' }}</p>
                <p>{{ item.create_time }}</p>
                <p>{{ item.file_suffix }}</p>
                <p>{{ item.file_size }}</p>
                <p>{{ item.id }}</p>
                <a target="_blank" :href="item.urls ? JSON.parse(item.urls)[langtype] : item.url"><i
                    class="el-icon-sold-out"></i></a>
              </li>
            </ul>
          </div>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page.sync="currentPage" :page-size="count" layout="total, prev, pager, next" :total="total">
            </el-pagination>
          </div>
        </div>
        <div class="news-cen" style="flex: 1" v-if="tabShou == 1">
          <!-- <div class="app-box">
            <img src="../../assets/img/327.jpg"
                 alt="">
            <p>*欢迎下载佳智惠APP</p>
          </div> -->
          <div class="datum-box">
            <ul>
              <li v-for="(item, index) in datumList" :key="index">
                <p :title="item.file_name">{{ item.file_names ? JSON.parse(item.file_names)[langtype] : item.file_name }}</p>
                <p>{{ item.create_time }}</p>
                <p>{{ item.file_suffix }}</p>
                <p>{{ item.file_size }}</p>
                <i class="el-icon-sold-out" @click="downloadFile(item)"></i>
              </li>
            </ul>
          </div>
          <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page.sync="currentPage" :page-size="count" layout="total, prev, pager, next" :total="total">
            </el-pagination>
          </div>
        </div>
        <div class="news-cen" style="flex: 1" v-if="tabShou == 2">
          <div class="introduce">
            <ul>
              <p v-html="supplierList.detailses ? JSON.parse(supplierList.detailses)[langtype] : supplierList.details"></p>
              <!-- <li v-for="(item, index) in supplierList"
                  :key="index">
                <img :src="item.img"
                     alt="" />
                <p>{{ item.expansion }}</p>
              </li> -->
            </ul>
          </div>
          <supplier :clientType="clientType"></supplier>
        </div>
        <div class="news-cen" style="flex: 1" v-if="tabShou == 3">
          <p v-html="userAgreement ? userAgreement[langtype] : one"></p>
        </div>
        <div class="news-cen" style="flex: 1" v-if="tabShou == 4">
          <p v-html="privacyClause ? privacyClause[langtype] : two"></p>
        </div>
        <div class="news-cen" style="flex: 1" v-if="tabShou == 5">
          <p v-html="returnAndRefundPolicy ? returnAndRefundPolicy[langtype] : three"></p>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('serve.zwcx')" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="product-box" v-if="productInfo">
        <div class="title-box">{{ $t('serve.cpxx') }}</div>
        <div class="des-box">
          <div class="description">{{ $t('serve.pcy') }}</div>
        </div>
        <div class="content">
          <div class="content-box">
            <div class="layui-card-header" id="productName"></div>
            <div class="layui-card-body">
              <p class="productName">{{ productInfo.productName }}</p>
              <p>{{ $t('serve.cpxh') }}：{{ productInfo.productType }}</p>
              <p>{{ $t('serve.ys') }}：{{ productInfo.productColor }}</p>
              <p>{{ $t('serve.sbh') }}：{{ productInfo.imei }}</p>
              <p>{{ $t('serve.ccrq') }}：{{ productInfo.factoryTime }}</p>
              <p>{{ $t('serve.sccj') }}：{{ productInfo.manufacturer }}</p>
              <p>{{ $t('serve.zjy') }}：{{ productInfo.quarantineName }}</p>
              <p>{{ $t('serve.xsryxm') }}：{{ productInfo.salesType }}</p>
              <p>{{ $t('serve.dlsmc') }}：{{ productInfo.agentsName }}</p>
              <div id="id">
                {{ $t('serve.gwrk') }}:
                <a href="https://www.bbjt88.com" target="_blank">www.bbjt88.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import Supplier from "./supplier.vue";
export default {
  props: [],
  data() {
    return {
      tabList: [
        { name: 'zlxz' },
        // { name: '真伪查询' },
        { name: "rjxz" },
        { name: "gysly" },
        { name: "yhxy" },
        { name: "ystk" },
        { name: "fhtkzc" },
        // { name: "客户留言" },
      ],
      location: "",
      tabShou: 0,
      content: true,
      input: "",
      page: 1,
      count: 6,
      total: null,
      currentPage: 2,
      type: 1,
      datumList: [],
      dialogVisible: false,
      productInfo: {},
      downloadOnce: false,
      clientType: null,
      supplierList: [],
      customerList: [],
      one: '',
      two: '',
      three: '',
      langtype: 0,
      privacyClause: '', // 隐私条款
      returnAndRefundPolicy: '',//发货退款政策
      userAgreement: '', // 用户协议
      swiperOption: {
        loop: true, // 是否循环轮播
        autoplay: true, // 是否可以自动轮播
        slidesPerView: 1, // 可是区域内可展示多少个块
        spaceBetween: 30, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperList: [
      ],
    };

  },
  name: "serve",
  components: { Supplier, swiper, swiperSlide },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  created() {
    this.location = this.$t('serve.zlxz');
    switch (localStorage.getItem('lang')) {
      case "zh":
        this.langtype = 0;
        break;
      case "en":
        this.langtype = 1;
        break;
      case "es":
        this.langtype = 6;
        break;
      case "ru":
        this.langtype = 2;
        break;
      case "de":
        this.langtype = 3;
        break;
      case "fr":
        this.langtype = 4;
        break;
      case "ar":
        this.langtype = 5;
        break;
    }
    if (this.$route.query.tab) {
      this.tabShou = this.$route.query.tab;
    }
    this.tabList.forEach((item, index) => {
      item.name = this.$t('serve.' + item.name)
    })
    // this.tabShou = 1
    this.getSupplierList();
    this.getCustomerList();
    // let _this = this
    // window.addEventListener("setItemEvent",function(e){
    //   //e.key : 是值发生变化的key
    //   if(e.key==="sharing"){
    //     setTimeout(()=> {
    //       console.log(e.newValue);
    // 			if(e.newValue == '资料下载') {
    //         _this.tabShou = 1
    // 				_this.type = 1
    //         _this.getDownloadList()
    // 			} else if(e.newValue == '软件下载') {
    //         _this.tabShou = 2
    //         _this.type = 0
    //         _this.getDownloadList()
    // 			}else if(e.newValue == '供应商留言') {
    //          _this.tabShou = 3
    //          _this.clientType = 1
    //          console.log(_this.clientType);
    //       }else if(e.newValue == '真伪查询') {
    //         _this.tabShou = 0
    //       } else {
    //         _this.tabShou = 4
    //         _this.clientType = 2
    //       }
    //     },500)
    //   }
    // })
  },
  async mounted() {
    if (this.$route.query.tab) {
      this.tabShou = this.$route.query.tab
    }
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      this.$wow.init();
    });
    this.getbannerList()
    const that = this;
    window.onresize = () => {
      return () => {
        window.screenWidth = document.body.clientWidth;
        window.screenHeight = document.body.clientHeight;
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
      };
    };
    this.getDownloadList();
    var res = await this.api.get(this.apiPath.userAgreement, {});
    console.log(res)
    this.one = res.description
    this.userAgreement = JSON.parse(res.descriptions.replace(/\r|\n/g, '<br/>'))
    var res = await this.api.get(this.apiPath.privacyClause, {});
    console.log(res)
    this.two = res.description
    this.privacyClause = JSON.parse(res.descriptions.replace(/\r|\n/g, '<br/>'))
    var res = await this.api.get(this.apiPath.returnAndRefundPolicy, {});
    console.log(res)
    this.three = res.description
    this.returnAndRefundPolicy = JSON.parse(res.descriptions.replace(/\r|\n/g, '<br/>'))
  },
  methods: {
    async getbannerList() {
      let res = await this.api.get(this.apiPath.serveSwiper, {});
      console.log(res)
      this.swiperList = res;
      console.log(this.swiperList)
      if (res.items.length == 0) {
        this.swiperList.items.push({
          img: res.img
        })
      }
    },
    handleClickSlide() {
      // console.log(222);
    },
    async getSupplierList() {
      let res = await this.api.get(this.apiPath.supplier, {});
      console.log(res)
      this.supplierList = res;
    },
    async getCustomerList() {
      let res = await this.api.get(this.apiPath.customer, {});
      this.customerList = res.items;
      console.log(res)
    },

    async addTab(item, index) {
      console.log(item, index);
      this.location = item.name;
      this.content = !this.content;
      this.tabShou = index;
      if (index == 1) {
        this.type = 0;
        this.getDownloadList();
      } else if (index == 3) {
        this.type = 3;
        // this.clientType = 1;
        console.log('no')
        var res = await this.api.get(this.apiPath.userAgreement, {});
        console.log(res)
        this.one = res.description
        this.userAgreement = JSON.parse(res.descriptions.replace(/\r|\n/g, '<br/>'))
        console.log(this.userAgreement)
      } else if (index == 4) {
        this.type = 4;
        console.log('ok')
        var res = await this.api.get(this.apiPath.privacyClause, {});
        console.log(res)
        this.two = res.description
        this.privacyClause = JSON.parse(res.descriptions.replace(/\r|\n/g, '<br/>'))
        // console.log(this.privacyClause[0])
      } else if (index == 5) {
        this.type = 5;
        var res = await this.api.get(this.apiPath.returnAndRefundPolicy, {});
        console.log(res)
        this.three = res.description
        this.returnAndRefundPolicy = JSON.parse(res.descriptions.replace(/\r|\n/g, '<br/>'))
      } else if (index == 2) {
        this.type = 0;
        this.getDownloadList();
      } else {
        this.type = 1;
        this.getDownloadList();
      }
    },
    async getDownloadList() {
      let data = {
        page: this.page,
        count: this.count,
        type: this.type,
      };
      let res = await this.api.get(this.apiPath.fileList, data);
      this.datumList = res.items;
      console.log(this.datumList);
      this.total = res.total;
      this.currentPage = res.page + 1;
    },
    handleSizeChange(val) {
      // 每页多少条
      this.count = val;
      this.getDownloadList();
    },
    handleCurrentChange(val) {
      // 当前页
      this.page = val;
      this.getDownloadList();
    },
    async downloadFile(item) {
      //只能下载一次
      if (this.downloadOnce) {
        this.$message({
          message: "请输入您要查询的IMEI号",
          type: "warning",
        });
        return;
      }
      this.downloadOnce = true;
      let data = {
        url: item.url,
        file_suffix: item.file_suffix,
      };
      let res = await this.api.post(this.apiPath.fileDownload, data);
      if (res.code == 200) {
        this.downloadFun(res.data, item.file_name, item.file_suffix);
      }
    },
    downloadFun(fileStream, file_name, file_suffix) {
      this.loading = true;
      const blob = this.dataURLtoBlob(fileStream);
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", file_name + file_suffix);
      document.body.appendChild(link);
      link.click();
      this.loading = false;
    },
    dataURLtoBlob(base64Str) {
      var bstr = atob(base64Str);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      // 下载的是excel格式的文件
      return new Blob([u8arr], { type: "application/vnd.ms-excel" });
    },
    authenticitys() {
      // 查询产品真伪
      if (this.input) {
        this.productClickHandler(this.input);
      } else {
        this.$message({
          message: "请输入您要查询的IMEI号",
          type: "warning",
        });
      }
    },
    handleClose(done) {
      // 关闭查询真伪弹窗
      done();
    },
    productClickHandler(e) {
      let theu = this;
      var xhr = new XMLHttpRequest(); // 创建xhr对象
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
            var result = JSON.parse(xhr.responseText); // 将字符串转化为对象，然后才能获取到返回字符串中的某一个值
            theu.productInfo = result.data;
            console.log(theu.productInfo); // 获取返回字符串中的某一个值
            if (result.data == null || result.data == []) {
              theu.$message({
                message: "查询结果为空，请输入正确的设备号！",
                type: "warning",
              });
              return false;
            }
            theu.dialogVisible = true;
          } else {
            alert("Request was unsuccessful: " + xhr.status);
          }
        }
      };
      var url =
        "https://wlb.babazhn.com/baba-device/proinfo/getProductInfoById?" +
        "imei=" +
        e; // 获取课程列表,带参数的get请求
      xhr.open("get", url, true); // 开启一个请求，但还没有向服务器端发起请求，执行后redayState的值变为1
      xhr.send(null); // 向服务器端发起请求，执行后redayState的值变为2
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.serve {
  margin-top: 135px;
}

.authenticity {
  height: 334px;
  width: 400px;
  display: flex;
  margin: auto;
  align-items: center;
}

.title-box {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin: 0 auto;
  margin-top: 40px;
}

.form-group {
  font-size: 15px;
  text-align: center;
  /*font-weight: bold;*/
  margin: 0 auto;
  margin-top: 20px;
}

.des-box {
  width: 100%;
}

.description {
  height: 40px;
  width: 60%;
  text-align: center;
  margin: auto;
  background: #337c6c;
  line-height: 40px;
  border-radius: 20px;
  color: #fff;
}

.content {
  width: 100%;
}

.content-box {
  margin-bottom: 100px;
  margin: 40px;
  background: #fff;
  border-radius: 30px;
  padding: 30px;

  .productName {
    border-bottom: 2px solid #eee;
    margin-bottom: 10px;
  }

  #id {
    margin-top: 10px;

    a {
      color: #337c6c;
    }
  }
}

.img-box {
  width: 100%;
}

.img-box img {
  width: 100px;
  height: 100px;
  display: block;
  text-align: center;
  margin: 0 auto;
}

.product-box {
  background: url("../../assets/img/bug.png");
  padding-bottom: 100px;
}

.center {
  background: #eee;
  padding: 20px 300px;

  .news-box {
    width: 100%;
    display: flex;
    // margin-top: 10px;
    border-top: 1px solid #eee;

    // padding-top: 20px;
    .tabs {
      text-align: center;
      margin-right: 20px;

      .title {
        width: 200px;
        height: 50px;
        line-height: 50px;
        background: #7f0114;
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 5px;
        color: #fff;
      }

      ul li {
        height: 40px;
        line-height: 40px;
        background: #fff;
        margin-bottom: 3px;
        color: #000;
        font-size: 18px;
        cursor: pointer;
      }

      ul .active {
        background: #7f0114;
        color: #fff;
        transition: 0.5s;
      }

      img {
        width: 200px;
        height: 150px;
      }
    }

    .news-cen {
      width: 100%;

      .app-box {
        align-items: center;
        text-align: center;
        background: #fff;
        background-image: url("../../assets/serve/bh33333.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 30px 0;

        img {
          width: 200px;
          height: 200px;
          margin: auto;
        }

        p {
          color: #7f0114;
        }
      }

      .high {
        height: 334px;
        line-height: 334px;
      }

      .datum-box {
        margin-top: 10px;

        ul {
          li {
            height: 90px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            background: #fff;
            margin-bottom: 20px;

            p {
              width: 160px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .el-icon-sold-out {
              font-size: 22px;
            }
          }

          li:hover {
            cursor: pointer;
            background: #7f0114;
            color: #fff;
            transition: 0.5s;
          }
        }
      }
    }
  }
}

.tabs-min {
  display: none;
}

.introduce {
  ul li {
    text-align: center;

    img {
      width: 200px;
      height: 70px;
    }
  }
}

@media screen and (max-width: 750px) {
  .breadcrumb {
    display: none;
  }

  .center {
    padding: 0;

    .news-box {
      padding: 0;
    }
  }

  .tabs {
    display: none;
  }

  .news-cen {
    padding: 0 15px;
  }

  .authenticity {
    width: 200px;
    margin-left: 10px;
  }

  .tabs-min {
    display: block;
    padding-top: 10px;

    ul {
      display: flex;
      justify-content: space-around;

      li {
        font-size: 17px;
        background: #fff;
      }

      .active {
        border-bottom: 1px solid #7f0114;
        background: #7f0114;
        color: #fff;
      }
    }
  }

  .datum-box {
    li {
      padding: 0 10px;
    }
  }
}

.swiper-box {
  position: relative;

  .btn-box {
    font-size: 20px;
    margin-top: 10px;

    span {
      color: rgb(95, 139, 200);
    }
  }

  .center0 {
    width: 100%;
    position: absolute;
    top: 240px;
    left: 30px;
    text-align: center;

    .name {
      font-size: 60px;
      color: #fff;
    }

    .title {
      font-size: 25px;
      color: #fff;
    }

    .description {
      font-size: 10px;
      color: #fff;
    }
  }

  .center1 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 80px;
    text-align: center;
    color: #000;

    .name {
      font-size: 22px;
    }

    .title {
      font-size: 50px;
    }

    .description {
      font-size: 30px;
    }
  }

  .center2 {
    width: 40%;
    position: absolute;
    top: 35%;
    right: 190px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }

    .title {
      font-size: 50px;
    }

    .description {
      font-size: 30px;
    }
  }

  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }

    .title {
      font-size: 50px;
    }

    .description {
      font-size: 30px;
    }
  }

  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }

    .title {
      font-size: 50px;
    }

    .description {
      font-size: 30px;
    }
  }
}
</style>
<style>
.serve .el-dialog__body {
  padding: 0px !important;
}

.el-input--medium .el-input__inner {
  width: 300px !important;
  height: 56px !important;
}

@media screen and (max-width: 750px) {
  .el-dialog {
    width: 100% !important;
  }

  .el-input--medium .el-input__inner {
    width: 200px !important;
    height: 56px !important;
  }
}

.primary {
  width: 100px;
  height: 54px;
}

.el-button--primary {
  color: #fff;
  background-color: #7f0114;
  border-color: #7f0114;
}

.el-button--primary:hover {
  background-color: #9a6a71;
}</style>
